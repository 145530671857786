import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {TimeoutEvent, TimeoutService} from '../services/timeout.service';
import {Subscription} from 'rxjs/internal/Subscription';
import {Router} from '@angular/router';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {BikeService} from '../services/bike.service';
import {WheelService} from '../services/wheel.service';
import {ValveService} from '../services/valve.service';
import {InnertubeService} from '../services/innertube.service';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {filter, flatMap} from 'rxjs/operators';
import {InnerTube} from '../model/InnerTube';
import {FilterRequest} from '../model/FilterRequest';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnDestroy, OnInit {
    private timeoutSubscription: Subscription;

    autoCompleteControl = new FormControl();

    filteredOptions: Observable<InnerTube[]>;
    filters: FilterRequest[] = [];

    bikeSize: string[] = [];
    bikeType: string[] = [];
    wheelWidth: string[] = [];
    valveType: string[] = [];

    // Selected Var
    selectedBikeSize = '-1'; // Taille de la roue
    selectedValveType = '-1'; // Type de valve
    selectedBikeType = '-1'; // Pratique
    selectedWheelWidth = '-1'; // Largeur

    constructor(private router: Router,
                public ngxSmartModalService: NgxSmartModalService,
                private timeoutService: TimeoutService,
                private bikeService: BikeService,
                private wheelService: WheelService,
                private valveService: ValveService,
                private innertubeService: InnertubeService) {}

    ngOnInit() {
        this.loadFilters();

        // Register the autocomplete
        this.filteredOptions = this.autoCompleteControl.valueChanges
            .pipe(filter(value => value))
            .pipe(flatMap(value => this.innertubeService.search(value)));

        // Register timeout in case of inactivity
        this.registerTimeout();
    }

    private registerTimeout() {
        if (this.timeoutSubscription) {
            this.timeoutSubscription.unsubscribe();
        }
        this.timeoutSubscription = this.timeoutService.registerNewTimeout(event => {
            if (event === TimeoutEvent.TIMEOUT) {
                console.log('Timeout');
                this.router.navigate(['/home']);
            } else if (event === TimeoutEvent.TIMEOUT_WARNING) {
                console.log('warning');
            }
        });
    }

    private loadFilters() {
        if (this.selectedBikeSize === '-1') {
            this.bikeService.getFiltersSize(this.filters).subscribe((data: string[]) => this.bikeSize = data);
        }
        if (this.selectedBikeType === '-1') {
            this.bikeService.getFiltersType(this.filters).subscribe((data: string[]) => this.bikeType = data);
        }
        if (this.selectedWheelWidth === '-1') {
            this.wheelService.getFiltersWidth(this.filters).subscribe((data: string[]) => this.wheelWidth = data);
        }
        if (this.selectedValveType === '-1') {
            this.valveService.getFiltersType(this.filters).subscribe((data: string[]) => this.valveType = data);
        }
    }

    private resetSelectedElement(data: FilterRequest[]) {
        data.forEach(one => {
            switch (one.type) {
                case 'bikeSize': this.selectedBikeSize = '-1'; break;
                case 'bikeType': this.selectedBikeType = '-1';  break;
                case 'wheelWidth': this.selectedWheelWidth = '-1'; break;
                case 'valveType': this.selectedValveType = '-1'; break;
            }
        });
    }

    bikeSizeUpdate(e) {
        const newVal = e.target.value;
        const firstIndex = this.filters.findIndex(one => one.type === 'bikeSize');

        // Reinit to the default value
        if (newVal === '-1' || firstIndex !== -1) {
            const deleted = this.filters.splice(firstIndex, this.filters.length);
            this.resetSelectedElement(deleted);
        } else {
            this.filters.push({
                'type': 'bikeSize',
                'value': e.target.value
            });
        }

        // Load filter
        this.loadFilters();
    }

    bikeTypeUpdate(e) {
        const newVal = e.target.value;
        const firstIndex = this.filters.findIndex(one => one.type === 'bikeType');

        // Reinit to the default value
        if (newVal === '-1' || firstIndex !== -1) {
            const deleted = this.filters.splice(firstIndex, this.filters.length);
            this.resetSelectedElement(deleted);
        } else {
            this.filters.push({
                'type': 'bikeType',
                'value': e.target.value
            });
        }

        // Load filter
        this.loadFilters();
    }

    wheelWidthUpdate(e) {
        const newVal = e.target.value;
        const firstIndex = this.filters.findIndex(one => one.type === 'wheelWidth');

        // Reinit to the default value
        if (newVal === '-1' || firstIndex !== -1) {
            const deleted = this.filters.splice(firstIndex, this.filters.length);
            this.resetSelectedElement(deleted);
        } else {
            this.filters.push({
                'type': 'wheelWidth',
                'value': e.target.value
            });
        }

        // Load filter
        this.loadFilters();
    }

    valveTypeUpdate(e) {
        const newVal = e.target.value;
        const firstIndex = this.filters.findIndex(one => one.type === 'valveType');

        // Reinit to the default value
        if (newVal === '-1' || firstIndex !== -1) {
            const deleted = this.filters.splice(firstIndex, this.filters.length);
            this.resetSelectedElement(deleted);
        } else {
            this.filters.push({
                'type': 'valveType',
                'value': e.target.value
            });
        }

        this.loadFilters();
    }

    displayAutocomplete(innertube?: InnerTube): string | undefined {
        return innertube ? innertube.name : undefined;
    }

    onSearchClick() {
        if (this.autoCompleteControl.value) {
            const data = { 'filters': [{ 'label': 'Reference', 'value': this.autoCompleteControl.value.name || this.autoCompleteControl.value}] };
            console.log('navigate to search-result');
            this.router.navigate(['/search-result', {'data': JSON.stringify(data) }]);
        } else {
            const data = { 'filters': [ ] };
            if (this.selectedBikeType !== '-1') {
                console.log('Add Filter Pratique');
                data.filters.push({'label': 'Pratique', 'value': this.selectedBikeType });
            }
            if (this.selectedBikeSize !== '-1') {
                console.log('Add Filter Taille de la roue');
                data.filters.push({'label': 'Taille de la roue', 'value': this.selectedBikeSize });
            }
            if (this.selectedWheelWidth !== '-1') {
                console.log('Add Filter Largeur');
                data.filters.push({'label': 'Largeur', 'value': this.selectedWheelWidth });
            }
            if (this.selectedValveType !== '-1') {
                console.log('Add Filter Type de valve');
                data.filters.push({'label': 'Type de valve', 'value': this.selectedValveType });
            }

            if (data.filters.length > 0) {
                console.log('navigate to search-result');
                this.router.navigate(['/search-result', {'data': JSON.stringify(data) }]);
            } else {
                console.log('navigate to no-result');
                this.router.navigate(['/no-result']);
            }
        }
    }

    @HostListener('document:click', ['$event'])
    public onDocumentClick(event: MouseEvent): void {
        // const targetElement = event.target as HTMLElement;
        console.log('Reset Timeout');
        this.registerTimeout();
    }

    ngOnDestroy() {
        console.log('Search was destroyed');
        this.ngxSmartModalService.closeLatestModal();
        if (this.timeoutSubscription) {
            this.timeoutSubscription.unsubscribe();
        }
    }
}
